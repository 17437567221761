import React, { useState, useEffect } from "react";
import axios from 'axios';
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import ServiceItem from '../components/Service';
import TrackVisibility from "react-on-screen";
import Progress from "../components/Progress";

function Service(){
  const [services, setServices] = useState([]);
  const [skills, setSkills] = useState([]);

  useEffect(() =>{
    axios.get('/api/skills')
    .then(response =>{
      setSkills(response.data);
    })
    axios.get('/api/services')
      .then(response =>{
        setServices(response.data)
      })
  }, [])

  return (
    <Layout>

      <div className="mi-service-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="Services" />
          <div className="mi-service-wrapper">
            <div className="row mt-30-reverse">
              {services.map(service => (
                <div className="col-lg-4 col-md-6 col-12 mt-30" key={service.title}>
                  <ServiceItem content={service}/>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
       <div className="mi-skills-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="My Skills" />
          <div className="mi-skills">
            <div className="row mt-30-reverse">
              {skills.map(skill => (
                <TrackVisibility once className="col-lg-6 mt-30" key={skill.title}>
                  <Progress title={skill.title} />
                </TrackVisibility>
              ))}
            </div>
          </div>
          <div className="mt-50"></div>
        </div>
      </div>
    </Layout>
  );
}

export default Service;
