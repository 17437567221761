import Mock from "../mock";

const database = {
  information: {
    name: 'Ahmed Nagy',
    fullName: 'Ahmed Mohamed Nagy',
    aboutContent: "I am a skilled software engineer dedicated to solving problems through clean and efficient code. \nWith a strong background in backend and mobile application development, I have also ventured into building machine learning solutions. \nMy expertise lies in Java, Kotlin, GO, and JavaScript.\nMy passion for coding ignited during my college years, initially as a hobby. As I honed my skills, I realized that software engineering was my true calling. Freelancing for computer science students allowed me to develop mobile apps as graduation projects while completing my Bachelor of Commerce studies.\nSince graduating, I have thrived as a software engineer by creating innovative and effective solutions for complex challenges. Attention to detail and a keen eye for visually stunning designs are additional strengths I bring to the table.\nI embrace continuous learning and eagerly take on new challenges, always striving to stay at the forefront of emerging technologies and techniques. Collaborating within teams is a strength I value greatly.\nAs the world of software engineering evolves, I am committed to delivering exceptional results by leveraging the latest advancements in the field.",
    aboutMe: "I started my career as mobile developer 'Android', By the time I developed myself to build cross-platform applications, Backend applications, and Machine leanring solutions",
    age: 29,
    phone: '',
    nationality: 'Egyptian',
    language: '',
    email: 'Ahmed.nagii2511@gmail.com',
    address: 'Cairo, Egypt',
    freelanceStatus: '',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/ahmed-nagy',
      dribbble: '',
      github: 'https://github.com/Ahmednagyyy'
    },
    brandImage: '/images/brand-image.png',
    aboutImage: '/images/about-image.png',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/empty.pdf'
  },
  services: [
    {
      title: "Spring Boot / Express.js\n(Backend Development)",
      icon: 'code',
      details: "I specialize in developing robust and scalable backend applications using Spring Boot and Express.js. \n\nWith a focus on clean code and best practices, I ensure that your backend is efficient, maintainable, and capable of handling high volumes of traffic. \n\nWhether you need API development, microservices architecture, or database integration, I can deliver tailored solutions to meet your specific requirements."
    },
    {
      title: "Android / React Native\n(Mobile App Development)",
      icon: 'mobile',
      details: "I offer professional mobile app development services for Android and React Native platforms. \n\nBy combining my expertise in coding with an eye for pixel-perfect design, I create seamless and engaging mobile applications. \n\nWhether you need a native Android app or a cross-platform React Native app, I can transform your ideas into feature-rich and interactive mobile experiences. \n\nFrom user-friendly interfaces to smooth performance, I strive to deliver mobile apps that exceed your expectations."
    }
  ],
  reviews: [
    // {
    //   id: 1,
    //   content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
    //   author: {
    //     name: 'Burdette Turner',
    //     designation: 'Web Developer, Abc Company'
    //   }
    // },
    // {
    //   id: 2,
    //   content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
    //   author: {
    //     name: 'Susan Yost',
    //     designation: 'Client'
    //   }
    // },
    // {
    //   id: 3,
    //   content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    //   author: {
    //     name: 'Irving Feeney',
    //     designation: 'Fiverr Client'
    //   }
    // }
  ],
  skills: [
    {
      title: "Spring boot",
      value: 100
    },
    {
      title: "Android Development",
      value: 100
    },
    {
      title: "React Native",
      value: 90
    },
    {
      title: "ExpressJs",
      value: 90
    },
    {
      title: "Java",
      value: 90
    },
    {
      title: "Kotlin",
      value: 90
    },
    {
      title: "Java Script",
      value: 90
    },
    {
      title: "Postgres",
      value: 90
    },
    {
      title: "MySQL",
      value: 90
    },
    {
      title: "MongoDb",
      value: 90
    },
    {
      title: "Redis",
      value: 90
    },
    {
      title: "Apache Kafka",
      value: 90
    },
    {
      title: "Google Cloud Platform",
      value: 90
    },
    {
      title: "Docker",
      value: 80
    },
    {
      title: "Kubernetes",
      value: 80
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Axis Pay For Electronic Payments",
      platform: "@Axis \n ( Backend - Integration )",
      subtitle: "Axis is the closest substitute to a “bank” for the unbanked by offering both a digital wallet paired with a physical Visa companion card \n"

      +"Together we offer diversified and complete offering with a full digital experience to the end-user providing:\n"
      
      +"- Payments: remote, online & offline (via virtual card)\n"
      
      +"- Money transfers\n"
      
      +"- Savings (interest-bearing)\n"
      
      +"- Digital access to credit via:\n"
      
      +"- Nano and business loans for micro-entrepreneurs & SMEs\n"
      
      +"- Instant access to earned wages for employee",
      imageUrl: "/images/portfolio-image-10.jpg",
      largeImageUrl: ["/images/portfolio-image-10.jpg"],
      url: 'https://www.axisapp.com/'
    },
    {
      id: 2,
      title: "Drops: Food & Grocery Shopping",
      platform: "@Exteme Solution \n ( Android )",
      subtitle: "An online shopping experience like no other!\n"
      +"Drops brings to you all the in-store variety and much more! Get all your favorite local and imported items on Drops! Online grocery shopping has never been better in Kuwait!",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3.jpg"],
      url: 'https://apps.apple.com/eg/app/drops-food-grocery-shopping/id1425917093'
    },
    {
      id: 3,
      title: "COVID Safe Paths",
      platform: "Open source \n ( Android - IOS )",
      subtitle: "Safe Paths is a global movement to develop free, open-source, privacy-by-design tools for residents, public health officials, and larger communities to flatten the curve of COVID-19, reduce fear, and prevent a surveillance-state response to the pandemic.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1.jpg"],
      url: 'https://www.pathcheck.org'
    },
    {
      id: 4,
      title: "AUC Mobile",
      platform: "@Exteme Solution \n ( Backend - Android - IOS )",
      subtitle: "AUC Mobile is the official mobile app for The American University in Cairo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      largeImageUrl: ["/images/portfolio-image-2.jpg"],
      url: 'https://apps.apple.com/eg/app/auc-mobile/id930051014'
    },
    {
      id: 5,
      title: "AUC Coronavirus Community Dashboard",
      platform: "@Exteme Solution \n ( Dataflow )",
      subtitle: "AUC is committed to providing a safe academic and working environment for its students, faculty and staff. We remain focused on the health and safety of our community. Check this page for updates on safety precautions at AUC.",
      imageUrl: "/images/portfolio-image-6.jpg",
      largeImageUrl: ["/images/portfolio-image-6.jpg"],
      url: 'https://www.aucegypt.edu/coronavirus/community-dashboard'
    },
    {
      id: 6,
      title: "Nogoum FM",
      platform: "@Exteme Solution \n ( Android )",
      subtitle: "The Nogoum FM Radio App, allows you to listen to your favorite shows, and watch them live. Connect with the station and your presenters at anytime from anywhere in the world.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: ["/images/portfolio-image-4.jpg"],
      url: 'https://play.google.com/store/apps/details?id=org.extremesolution.nogoumfm&hl=en&gl=US'
    },
    {
      id: 7,
      title: "Nile FM",
      platform: "@Exteme Solution \n ( Android )",
      subtitle: "The Nile FM Radio App, allows you to listen to your favorite shows, and watch them live. Connect with the station and your presenters at anytime from anywhere in the world.",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5.jpg"],
      url: 'https://play.google.com/store/apps/details?id=org.extremesolution.nilefm&hl=en&gl=US'
    },
    {
      id: 8,
      title: "Egyptian professionals Network",
      platform: "@Exteme Solution \n ( Backend ) ",
      subtitle: "EPN is a global and diverse community of Egyptian professionals, business leaders, C-level executives and influential decision makers. EPN provides a vital and interactive space for minds to meet, share, collaborate and help each other no matter where we are. Here is a glimpse of the diversity within our community",
      imageUrl: "/images/portfolio-image-7.jpg",
      largeImageUrl: ["/images/portfolio-image-7.jpg"],
      url: 'https://epners.com/'
    },
    // {
    //   id: 2,
    //   title: "Coffee Mug",
    //   subtitle: "Awesome coffee mug design.",
    //   imageUrl: "/images/portfolio-image-4.jpg",
    //   largeImageUrl: [
    //     "/images/portfolio-image-4-lg.jpg",
    //     "/images/portfolio-image-4-lg2.jpg"
    //   ],
    //   url: 'https://facebook.com'
    // },
    // {
    //   id: 3,
    //   title: "Tea & Coffee Mug",
    //   subtitle: "Beautiful mug with logo.",
    //   imageUrl: "/images/portfolio-image-2.jpg",
    //   url: 'https://pinterest.com'
    // },
    // {
    //   id: 4,
    //   title: "Pen Holder",
    //   subtitle: "A pen holder with beautiful design.",
    //   imageUrl: "/images/portfolio-image-3.jpg",
    //   largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
    //   url: '#'
    // },
    // {
    //   id: 5,
    //   title: "Mug",
    //   subtitle: "Mug with awesome style",
    //   imageUrl: "/images/portfolio-image-5.jpg",
    //   largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    // }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "Jan 2023 - Present",
        position: "Backend Chapter Lead",
        company: "Axis",
        details: "- Design, and Implement scalable microservices providing fintech features to the end-user.\n"
        +"- Integrating with different service providers.\n"
        +"- Technical leader and valued contributor in an autonomous, cross-functional team.\n"
        +"- Help in planning projects development and timelines.\n"
        +"- Drive the best engineering practices within my team, define and teach these.\n"
        +"- Collaborate with other engineers, product owners, and designers to identify and solve challenging problems.\n"
        +"- Find ways to spread learning and knowledge sharing across the organization, leveling up our engineering practices and mentoring other engineers."
      },
      {
        id: 2,
        year: "Mar 2022 - Jan 2023",
        position: "Senior Software Engineer",
        company: "Axis",
        details: "- Working on Axis-Pay fintech product.\n"
        +"- Design, and Implement scalable microservices providing fintech features to the end-user\n"
        +"- Integrating with different service providers.\n"
        +"- Taking part in the requirements validation process by taking technical decisions affecting the business decisions.\n"
        +"- Designing the API Blueprint (+ mocking) based on the business requirements, helping out all the relevant parties (Design, Backend, Frontend) to work in parallel.\n"

      },
      {
        id: 3,
        year: "Feb 2021 - Mar 2022",
        position: "Lead Software Engineer",
        company: "Extreme Solution",
        details: "Leading dev team a cross different technology stacks.\n"
        +"- Technical leader and valued contributor in an autonomous, cross-functional team.\n"
        +"- Help in planning projects development and timelines\n"
        +"- Drive the best engineering practices within my team, define and teach these.\n"
        +"- Collaborate with other engineers, product owners, and designers to identify and solve challenging problems.\n"
        +"- Find ways to spread learning and knowledge sharing across the organization, leveling up our engineering practices and mentoring other engineers\n"
      },
      {
        id: 4,
        year: "Apr 2020 - Jul 2020",
        position: "COVID Safe Paths Volunteer - Software Engineer",
        company: "MIT - Massachusetts Institute of Technology",
        details: "- Implementing cross-platform mobile applications using React-Native.\n"
        +"- Implementing Backend services using Spring Boot\n"
        +"- Implementing reactive components to support RTL languages\n"
      },
      {
        id: 5,
        year: "Mar 2018 - Feb 2021",
        position: "Software Engineer",
        company: "Extreme Solution",
        details: "- Main role is implementing native and cross-platform mobile applications using React-Native.\n"
        +"- Implementing Backend services using Spring Boot\n"
        +"- Implementing Machine learning solutions using Tensorflow\n"
        +"- Taking part in the requirements validation process by taking technical decisions affecting the business decisions of the apps.\n"
        +"- Designing the API Blueprint (+ mocking) based on the business requirements, helping out all the relevant parties (Design, Backend, Front-end) to be working in parallel.\n"
        +"- Integrating our apps with different third-parties offering services like navigation, food ordering, payment gateways, events registration and bikes reservation systems\n"
      },
      {
        id: 6,
        year: "Jan 2016 - Mar 2018",
        position: "Android Developer",
        company: "",
        details: "- Worked as a freelancer for Misr university for science and technology students\n"
        +"- Implementing native Android applications\n"
        +"- Help and mentor students in anything related to android framework"
      }
    ],
    educationExperience: [
      // {
      //   id: 1,
      //   year: "2018 - 2019",
      //   graduation: "Master of Science",
      //   university: "Abc University",
      //   details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      // },
      // {
      //   id: 2,
      //   year: "2016 - 2018",
      //   graduation: "Bachelor of Commerce",
      //   university: "Abc University",
      //   details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      // },
      // {
      //   id: 3,
      //   year: "2015 - 2016",
      //   graduation: "Higher Schoold Graduation",
      //   university: "Abc College",
      //   details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      // }
    ]
  },
  blogs: [
    // {
    //   id: 1,
    //   title: 'Markdown & Html supported blog.',
    //   featuredImage: '/images/blog-image-1.jpg',
    //   filesource: '../../blog/markdown-html-supported-blog.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 2,
    //   title: 'Installing NodeJS on your device.',
    //   featuredImage: '/images/blog-image-2.jpg',
    //   filesource: '../../blog/installing-nodejs-on-your-device.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 3,
    //   title: 'UI/UX design starter with Adobe XD.',
    //   featuredImage: '/images/blog-image-3.jpg',
    //   filesource: '../../blog/uiux-design-starter-with-adobe-xd.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 4,
    //   title: 'Boost your post for increasing sales.',
    //   featuredImage: '/images/blog-image-4.jpg',
    //   filesource: '../../blog/boost-your-post-for-increasing-sales.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // }
  ],
  contactInfo: {
    phoneNumbers: ['+01018082071'],
    emailAddress: ['ahmed.nagii2511@gmail.com'],
    address: "El-Manial, Cairo 11451, Egypt"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});
