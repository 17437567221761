import React, { useState, useEffect } from "react";
import axios from 'axios';
import Sectiontitle from "../components/Sectiontitle";
import Smalltitle from '../components/Smalltitle';
import Layout from "../components/Layout";
import Resume from "../components/Resume";

function Resumes(){
  const [workingExperience, setWorkingExperience] = useState([]);

  useEffect(() =>{
    axios.get('/api/experience')
      .then(response =>{
        setWorkingExperience(response.data.workingExperience);
      })
  }, [])

  return (
    <Layout>
      <div className="mi-resume-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Resume" />
          <Smalltitle title="Working Experience" icon="briefcase" />
          <div className="mi-resume-wrapper">
            {workingExperience.map(workingExp => (
              <Resume key={workingExp.id} resumeData={workingExp} />
            ))}
          </div>
          <div className="mt-30"></div>
          {/* <Smalltitle title="Educational Qualifications" icon="book" />
          <div className="mi-resume-wrapper">
            {educationExperience.map(educatonExp => (
              <Resume key={educatonExp.id} resumeData={educatonExp}/>
            ))}
          </div> */}
        </div>
      </div>
    </Layout>
  );
}

export default Resumes;
